.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 0; /* Removed padding */
  border-radius: 8px;
  width: 100%;
  max-width: 800px; /* Increased maxWidth */
  box-sizing: border-box;
  margin-top: -30px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
  position: relative; /* Added position relative to position suggestions below input */
}

.input {
  padding: 10px; /* Adjusted padding */
  margin-top: 5px;
  width: calc(100% - 20px); /* Adjusted width to match textarea */
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: none; /* Disable manual resizing */
  height: auto; /* Allow automatic height adjustment */
  max-height: 200px; /* Set a maximum height after which it scrolls */
  overflow-y: auto; /* Enable vertical scrolling */
  font-family: Arial, sans-serif; /* Corrected font */
  color: #333; /* Changed to dark grey */
  font-size: 0.9em;
}

.input::placeholder {
  color: #aaa;
}

.button {
  padding: 8px 15px;
  background-color: #5693f5; /* Changed to match the new blue */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 36px;
}

.label {
  font-weight: bold;
  color: #333; /* Changed to dark grey */
}

.description {
  color: #333; /* Changed to dark grey */
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.icon {
  margin-right: 4px;
  position: relative;
  top: 2px;
}

.suggestionsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  z-index: 1000;
  width: 100%; /* Adjusted width to match input */
  top: calc(100% + 1px); /* Position suggestions below the input */
  left: 0; /* Align suggestions with the input */
}

.suggestionItem {
  padding: 10px;
  cursor: pointer;
  color: #333; /* Changed to dark grey */
}

.suggestionItem:hover {
  background-color: #f4f4f9; /* Really light grey background color on hover */
  color: #333; /* Dark grey text on hover */
}

.response-container {
  margin-top: 15px;
  background-color: #f4f4f9;
  padding: 0 15px;
  border-radius: 4px;
  font-size: 0.95em;
  width: 100%; /* Make the response container full width */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width */
}

.siteButtons {
  margin-top: -15px;
  margin-bottom: -10px;
}

.siteButtons button {
  border: 2px solid #4d8df5; /* Blue outline */
  color: #4d8df5; /* Blue text */
  background-color: #fff; /* White fill */
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px; /* Right margin */
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 10px;
}

.siteButtons button:hover {
  background-color: #4d8df5; /* Blue background on hover */
  color: #fff; /* White text on hover */
}

.siteButtons div {
  font-size: 0.9em; /* Make the title smaller */
  margin: 10px 0; /* Add some space below the title */
  color: #333;
}

.siteButtons button.active {
  background-color: #4d8df5; /* Lighter blue background for active state */
  color: #fff; /* White text for active state */
}

.search-duration {
  font-size: 0.95em; /* Slightly smaller font size */
  color: #666; /* Medium grey color */
  margin: 20px 0 -5px 0
}

.advanced {
  margin-top: 20px;
  border-radius: 4px;
  width: 100%; /* Make the advanced section full width */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.advanced-toggle {
  display: inline-flex; /* Change from flex to inline-flex to prevent stretching */
  align-items: center;
  cursor: pointer;
  color: #777; /* Dark grey text */
  font-size: 0.9em; /* Normal font size */
  transition: color 0.3s ease;
  margin-top: -5px;
}

.advanced-toggle .icon {
  margin-right: 5px; /* Space between icon and text */
}

.defaultSpecifications {
  margin-top: 15px;
}

.defaultSpecifications .label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9em; /* Slightly smaller font size */
  color: #333; /* Dark grey color */
}

.defaultSpecifications .input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 4px;
  font-size: 0.9em; /* Slightly smaller font size */
  color: #333; /* Dark grey color */
  transition: border-color 0.3s ease;
}

.defaultSpecifications .input:focus {
  border-color: #4d8df5; /* Blue border on focus */
  outline: none; /* Remove default outline */
}

.deepSearchButton {
  background-color: #ffffff;
  border: 2px solid #4d8df5;
  color: #4d8df5;
}

.deepSearchButton:hover {
  background-color: #4d8df5;
  color: #ffffff;
}

.tabs {
  display: flex;
  justify-content: flex-end; /* Align tabs to the right */
  margin-bottom: 20px;
}

.tab {
  padding: 5px 0; /* Reduced padding */
  cursor: pointer;
  background-color: #fff;
  color: #4d8df5;
  transition: color 0.3s ease;
  text-decoration: none; /* Remove underline */
  font-size: 0.95em; /* Smaller font size */
}

.tab.active {
  text-decoration: underline; /* Underline when active */
}

.tab:hover {
  text-decoration: underline; /* Underline on hover */
}

.productFormContainer {
  width: 100%;
  max-width: 800px;
  margin: 30px auto;
  padding: 30px 30px 30px 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.customModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.customModal {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  padding: 20px 25px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 450px;
  max-height: 80vh;
  box-sizing: border-box;
  overflow: hidden;
}

.customModal h2 {
  margin: 0;
  padding: 15px 0;
  color: #333;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
}

.customModal .fieldsContainer {
  overflow-y: auto;
  flex-grow: 1;
  padding: 0 0 10px 0;
}

.customModal label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #333;
}

.customModal input {
  width: calc(100% - 20px);
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9em;
  color: #333;
  margin-bottom: 12px;
}

.customModal button {
  display: block;
  margin: 10px auto 0 auto;
}

.customModal select {
  background-color: transparent; /* Remove background color */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 0.9em;
  color: #333;
  width: 100%;
  box-sizing: border-box;
}

.customModal .fieldsContainer > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.customModal .closeButton {
  position: absolute;
  top: -7px;
  right: 10px;
  font-size: 2em;
  color: #666;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 20;
}
