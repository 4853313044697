.wrapper {
  width: 100%;
  text-align: center;
}

.resultsTitle {
  font-size: 1.2rem;
  margin: 20px 0;
  color: #333;
}

.container {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping of product containers */
  width: 100%;
  justify-content: center; /* Center items horizontally */
  min-height: 250px; /* Ensure the container maintains a minimum height */
}

.product {
  flex: 0 1 250px; /* Set the product container width to 250px */
  margin: 10px;
  padding: 10px 10px 10px 10px; /* Reduced padding */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  min-width: 300px; /* Set a fixed width for the product containers */
  text-align: center;
  position: relative;
  top: -5px;
  overflow: hidden; /* Ensure the product container hides overflow */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure the "View Product" button is at the bottom */
}

.image {
  width: 100%; /* Ensure the image takes the full width of the container */
  height: auto; /* Maintain aspect ratio */
  max-height: 200px; /* Ensure the image does not exceed the container's max height */
  border-radius: 4px;
  object-fit: contain; /* Ensure the image is contained within the container without stretching */
}

.price {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.link {
  display: inline-block;
  padding: 10px 30px;
  background-color: white; /* White background */
  color: #5a9bf6; /* Slightly lighter blue text */
  text-decoration: none;
  border-radius: 4px;
  border: 2px solid #5a9bf6; /* Slightly lighter blue outline */
  margin-top: auto; /* Push the button to the bottom */
  width: fit-content; /* Make the button not full width */
  align-self: center; /* Center the button horizontally */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
  cursor: pointer;
}

.link:hover {
  background-color: #5a9bf6; /* Slightly lighter blue background on hover */
  color: white; /* White text on hover */
}

.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px; /* Ensure the empty state container has a height */
  color: #999;
  font-size: 1rem;
}

.topChoiceTag {
  position: absolute;
  top: 10px;
  right: 10px; /* Move to the top right of the container */
  background-color: #5a9bf6; /* Slightly lighter blue background for top choice */
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
  z-index: 10; /* Ensure the tag is above other elements */
}

.summary {
  text-align: left; /* Align text to the left */
}

.googleShoppingLink {
  display: inline-block;
  padding: 10px 20px;
  color: #5a9bf6; /* Blue text */
  text-decoration: none;
  margin: 5px 0; /* Small margin for spacing */
  padding-top: 5px; /* Add appropriate top padding */
  cursor: pointer;
  font-size: 12px;
  width: fit-content; /* Make the link not span full width */
  align-self: center; /* Center the link horizontally */
}

.vendor {
  font-size: 0.9rem; /* Slightly smaller font size */
  color: #666; /* Gray color for vendor text */
  margin-top: 0; /* Small margin on top for spacing */
}

.searchQuery {
  font-size: 0.95rem; /* Slightly larger font size */
  color: #666; /* Darker color for better readability */
  margin: -5px 0 20px 0; /* Space below the text */
  text-align: center; /* Center the text */
}

.loadingSummaries {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end; /* Align to the far right side */
  width: 100%;
  height: auto;
  color: #999;
  font-size: 0.8rem;
  text-align: center;
  z-index: 1000; /* Ensure it is above other elements */
  flex-direction: row; /* Align items horizontally */
}

.loadingSummaries .spinner {
  border: 3px solid rgba(0, 0, 0, 0.1); /* Make the border thicker */
  border-left-color: #5a9bf6; /* Blue color for the spinner */
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
  margin-right: 10px; /* Add some space between spinner and text */
  position: relative;
  top: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.improveResultsButton {
  background-color: #5a9bf6; /* Blue background color */
  color: white; /* White text color */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Add padding for better spacing */
  font-size: 1rem; /* Set font size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  margin: 10px 0; /* Add margin for spacing */
}

.improveResultsButton:hover {
  background-color: #4a8be6; /* Darker blue on hover */
}

.improveResultsButton:disabled {
  background-color: #cccccc; /* Gray background when disabled */
  cursor: not-allowed; /* Not-allowed cursor when disabled */
}

.deepSearchLink {
  color: #666; /* Gray color */
  text-decoration: underline; /* Underlined text */
  cursor: pointer;
}
